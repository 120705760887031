import './i18n/config';
import './Wrapper.css';
import { FiHome} from "react-icons/fi";
import { useState, useEffect, useRef } from 'react';
import moment from 'moment';
import Popup from 'reactjs-popup';
import DynamicHTMLContent from './components/DynamicHTMLContent'
import EQL, {EQLGame, EQLInfoMessage} from './lib/eql'
import { useTranslation } from 'react-i18next';

declare global {
  interface Window { EQL: EQLGame }
}

window.EQL = new EQL()

function Wrapper() {
  const [headerHeight, setHeaderHeight] = useState('0px');
  const container = useRef<HTMLDivElement>(null);
  const replayHeader = useRef<HTMLDivElement>(null);
  const footer = useRef<HTMLDivElement>(null);
  const [realityCheckOpen, setRealityCheckOpen] = useState<boolean>(window.EQL.STATE.realityCheckOpen)
  const [helpContent, setHelpContent] = useState<string>(window.EQL.STATE.helpContent)
  const [helpOpen, setHelpOpen] = useState<boolean>(window.EQL.STATE.helpOpen)
  const [rulesContent, setRulesContent] = useState<string>(window.EQL.STATE.rulesContent)
  const [rulesOpen, setRulesOpen] = useState<boolean>(window.EQL.STATE.rulesOpen)
  const [settingsConfig, setSettingsConfig] = useState<any>(window.EQL.STATE.settingsConfig)
  const [settingsOpen, setSettingsOpen] = useState<boolean>(window.EQL.STATE.settingsOpen)
  const [currentTime, setCurrentTime] = useState<Date>(window.EQL.STATE.currentTime)
  const [wins, setWins] = useState<number>(window.EQL.STATE.wins)
  const [totalWon, setTotalWon] = useState<number>(window.EQL.STATE.totalWon)
  const [cost, setCost] = useState<number>(window.EQL.STATE.cost)
  const [balance, setBalance] = useState<number>(window.EQL.STATE.balance)
  const [bonusBalance, setBonusBalance] = useState<number>(window.EQL.STATE.bonusBalance)
  const [showWrapper, setShowWrapper] = useState<boolean>(window.EQL.STATE.showWrapper)
  const [showReplayHeader, setShowReplayHeader] = useState<boolean>(window.EQL.STATE.showReplay)
  const [sound, setSound] = useState<boolean>(window.EQL.STATE.sound)
  const [content, setContent] = useState<string | null>(null)
  const [infoTitle, setInfoTitle] = useState<string>("")
  const [infoContent, setInfoContent] = useState<string>("")
  const [infoOpen, setInfoOpen] = useState<boolean>(false)
  const [infoQuit, setInfoQuit] = useState<boolean>(false)
  const [bonusTitle, setBonusTitle] = useState<string>("")
  const [bonusContent, setBonusContent] = useState<string>("")
  const [bonusOpen, setBonusOpen] = useState<boolean>(false)
  const [bonusEndTitle, setEndBonusTitle] = useState<string>("")
  const [bonusEndContent, setEndBonusContent] = useState<string>("")
  const [bonusEndOpen, setEndBonusOpen] = useState<boolean>(false)
  const [switchToMoneyMode, setSwitchToMoneyMode] = useState<boolean>(false)
  const [language, setLanguage] = useState<string>("")
  const [betId, setBetId] = useState<string | null>(null)
  const [bonusRound, setBonusRound] = useState<any>(null)
  const { i18n, t } = useTranslation();

  const closeInfo = () => {
    setInfoTitle("")
    setInfoContent("")
    setInfoOpen(false)
    if(infoQuit) {
      window.EQL.ReturnToLobby();
    }
    setInfoQuit(false);
  }

  const closeBonus = () => {
    setBonusTitle("")
    setBonusContent("")
    setBonusOpen(false)
  }

  const closeEndBonus = () => {
    setEndBonusTitle("")
    setEndBonusContent("")
    setEndBonusOpen(false)
  }

  const closeSwitchToMoneyMode = () => {
    setSwitchToMoneyMode(false)
  }

  useEffect(() => {
    if (replayHeader.current && showReplayHeader) {
      setHeaderHeight('3vh');
    } else {
      setHeaderHeight('0px');
    }
  }, [showReplayHeader]);

  useEffect(() => {
    window.EQL.RegisterListener("wrapper","set_betid", setBetId);
    window.EQL.RegisterListener("wrapper","set_language", setLanguage);
    window.EQL.RegisterListener("wrapper","help_open",setHelpOpen);
    window.EQL.RegisterListener("wrapper","rules_open",setRulesOpen);
    window.EQL.RegisterListener("wrapper","realitycheck_open",setRealityCheckOpen);
    window.EQL.RegisterListener("wrapper","settings_open",setSettingsOpen);
    window.EQL.RegisterListener("wrapper","help_content",setHelpContent);
    window.EQL.RegisterListener("wrapper","rules_conent",setRulesContent);
    window.EQL.RegisterListener("wrapper","settings_config",setSettingsConfig);
    window.EQL.RegisterListener("wrapper","set_wins",setWins);
    window.EQL.RegisterListener("wrapper","set_total_won",setTotalWon);
    window.EQL.RegisterListener("wrapper","set_cost",setCost);
    window.EQL.RegisterListener("wrapper","set_balance",setBalance);
    window.EQL.RegisterListener("wrapper","set_bonus_balance",setBonusBalance);
    window.EQL.RegisterListener("wrapper","set_bonus_round",setBonusRound);
    window.EQL.RegisterListener("wrapper","show_wrapper",setShowWrapper);
    window.EQL.RegisterListener("wrapper","show_replay",setShowReplayHeader);
    window.EQL.RegisterListener("wrapper","set_content",setContent);
    window.EQL.RegisterListener("wrapper","set_current_time",setCurrentTime);
    window.EQL.RegisterListener("wrapper","set_sound", setSound);
    window.EQL.RegisterListener("wrapper","switch_to_money_mode", setSwitchToMoneyMode);
    window.EQL.RegisterListener("wrapper","show_info", 
          (c:EQLInfoMessage) => {
            setInfoContent(c.content)
            setInfoTitle(c.title)
            if(c.quit) {
              setInfoQuit(true)
            }
            setInfoOpen(true)
          }
    );
    window.EQL.RegisterListener("wrapper","show_bonus", 
      (c:EQLInfoMessage) => {
        setBonusContent(c.content)
        setBonusTitle(c.title)
        setBonusOpen(true)
      }
    );
    window.EQL.RegisterListener("wrapper","show_end_bonus", 
      (c:EQLInfoMessage) => {
        setEndBonusContent(c.content)
        setEndBonusTitle(c.title)
        setEndBonusOpen(true)
      }
    );
    window.EQL.RegisterListener("wrapper","update_size", 
      () => {
        if (container.current) {
          window.EQL.SetDisplaySize({
            width: container.current.offsetWidth,
            height: container.current.offsetHeight,
          })
        }
    
        if (footer.current) {
          window.EQL.SetWrapperSize({
            width: footer.current.offsetWidth,
            height: footer.current.offsetHeight,
          });
        }
      }
    );
      
    if(!content) {
      window.EQL.Initialize(false)
    }
  },[content]);

  useEffect(() => {
    window.EQL.SetI18n(i18n, t)
  }, [t, i18n]);

  return language ?
    (<div className="wrapper">
      {showReplayHeader && (
      <div className="replay-header" ref={replayHeader}>
        {window.EQL.GetMessage('replay')}
      </div>)}
      <div ref={container} className="wrapper-content" style={{ marginTop: `${headerHeight}` }}>
        {content?(<DynamicHTMLContent html={content as string} />):<div style={{backgroundColor: '#000000', height: '100%', width: '100%'}} />}
      </div>
      {showWrapper && (<div className="wrapper-footer" ref={footer}>
        <div className="info">
          {window.EQL.LOBBY_URL && (<div className="home">
            <FiHome onClick={window.EQL.ReturnToLobby}/>
          </div>)}
          <div className="balance">{window.EQL.GetMessage('balance')}: {window.EQL.FormatCurrency(balance/100)}</div>
          <div className="wins">
            {window.EQL.GetMessage('total_won') && (window.EQL.GetMessage('total_won')+': '+window.EQL.FormatCurrency(totalWon/100))}
            {betId && ('ID: '+betId)}
          </div>
          <div className="cost">{window.EQL.GetMessage('total_cost')}: {window.EQL.FormatCurrency(cost/100)}</div>
        </div>
        <div className="controls">
          {window.EQL.LOBBY_URL && (<div className="home" />)}
          <div className="left-controls">
            {window.EQL.GetMessage('bonus_balance') && (window.EQL.GetMessage('bonus_balance') + ': ' + window.EQL.FormatCurrency(bonusBalance/100))}
            {
              //bonusRound?'Bonus Rounds: '+bonusRound.count+' / '+window.EQL.FormatCurrency(bonusRound.value*bonusRound.lines/100):''
              (bonusRound && !showReplayHeader)?'Free Games Remaining: '+bonusRound.count:''
            }
          </div>
          <div className="spacer"></div>
          <div className="right-controls">
            {moment(currentTime).format('LTS')}
          </div>
        </div>
      </div>)}
      <Popup open={helpOpen} closeOnDocumentClick modal nested onClose={window.EQL.CloseHelp}>
        <div className="modal">
          <button className="close" style={{color: "#444444"}} onClick={window.EQL.CloseHelp}>
            &times;
          </button>
          <div className="header">Help</div>
          <div className="content">
            {helpContent}
          </div>
        </div>
      </Popup>
      <Popup open={rulesOpen} closeOnDocumentClick modal nested onClose={window.EQL.CloseRules}>
        <div className="modal">
          <button className="close" style={{color: "#444444"}} onClick={window.EQL.CloseRules}>
            &times;
          </button>
          <div className="header">Rules</div>
          <div className="content">
            {rulesContent}
          </div>
        </div>
      </Popup>
      <Popup open={settingsOpen} closeOnDocumentClick modal onClose={window.EQL.CloseSettings}>
        <div className="modal">
          <button className="close" style={{color: "#444444"}} onClick={window.EQL.CloseSettings}>
            &times;
          </button>
          <div className="header">Settings</div>
          <div className="content">
            {settingsConfig}
          </div>
        </div>
      </Popup>
      <Popup open={infoOpen} closeOnDocumentClick modal onClose={closeInfo}>
        <div className="modal">
          <button className="close" style={{color: "#444444"}} onClick={closeInfo}>
            &times;
          </button>
          <div className="header">{infoTitle}</div>
          <div className="content">
            <div>{infoContent}</div>
          </div>
        </div>
      </Popup>
      <Popup open={bonusOpen} closeOnDocumentClick modal>
        <div className="modal">
          <div className="header">{bonusTitle}</div>
          <div className="content">
            <div>{bonusContent}</div>
          </div>
          <div className="content">
              <button style={{color: "#444444"}} onClick={closeBonus}>Start my free games now</button>
          </div>
        </div>
      </Popup>
      <Popup open={bonusEndOpen} closeOnDocumentClick modal>
        {window.EQL.STATE.bonusReload?
        (<div className="modal">        
          <div className="header">Keep Playing</div>
          <div className="content">
            <div>You have more free games!</div>
          </div>
          <div className="content">
              <button style={{color: "#444444"}} onClick={()=>window.EQL.Reload()}>Continue</button>
          </div>
        </div>):(<div className="modal">        
          <div className="header">{bonusEndTitle}</div>
          <div className="content">
            <div>{bonusEndContent}</div>
          </div>
          <div className="content">
              <button style={{color: "#444444"}} onClick={closeEndBonus}>Real Money</button>
              <button style={{color: "#444444"}} onClick={window.EQL.ReturnToLobby}>{window.EQL.GetMessage('return_to_lobby')}</button>
          </div>
        </div>)}
      </Popup>
      <Popup open={realityCheckOpen} closeOnDocumentClick={false} modal onClose={window.EQL.CloseRealityCheck}>
        <div className="modal">
          <button className="close" style={{color: "#444444"}} onClick={window.EQL.CloseRealityCheck}>
            &times;
          </button>
          <div className="header">
            {window.EQL.GetMessage('reality_check')}
          </div>
          <div className="content">
              <button style={{color: "#444444"}} onClick={window.EQL.ReturnToLobby}>{window.EQL.GetMessage('return_to_lobby')}</button>
              <button style={{color: "#444444"}} onClick={window.EQL.CloseRealityCheck}>{window.EQL.GetMessage('continue')}</button>
          </div>
        </div>
      </Popup>
      <Popup open={switchToMoneyMode} closeOnDocumentClick modal onClose={closeSwitchToMoneyMode}>
        <div className="modal">
          <button className="close" style={{color: "#444444"}} onClick={closeSwitchToMoneyMode}>
            &times;
          </button>
          <div className="header">
            {window.EQL.GetMessage('switch_to_money_mode')}
          </div>
          <div className="content">
              <button style={{color: "#444444"}} onClick={()=>window.EQL.SetPlayMode('M')}>{window.EQL.GetMessage('switch_to_money_mode_button')}</button>
              <button style={{color: "#444444"}} onClick={closeSwitchToMoneyMode}>{window.EQL.GetMessage('continue')}</button>
          </div>
        </div>
      </Popup>
    </div>
  ):(<div className="wrapper"/>);
}

export default Wrapper;
